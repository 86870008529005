import { Component, OnInit } from "@angular/core";
import { AlertController, ActionSheetController } from "@ionic/angular";
import { AuthenticationService } from "../../../services/auth/authentication.service";
import { LanguageService } from "../../../services/language/language.service";
import { CommonService } from "../../../services/common/common.service";
import { CustomizationfileService } from "../../../services/customizationfile/customizationfile.service";

@Component({
  selector: "app-sidebar-menu",
  templateUrl: "./sidebar-menu.component.html",
  styleUrls: ["./sidebar-menu.component.scss"]
})
export class SidebarMenuComponent implements OnInit {
  public lang;
  public sidebarMenu = false;
  public menuSidebar = [];
  public profile: any;
  public avatar: any;
  public active: boolean;
  currentUser = false
  darkMode: boolean = true;
  constructor(
    public alertController: AlertController,
    public actionSheetController: ActionSheetController,
    public _auth: AuthenticationService,
    public _language: LanguageService,
    public _cs: CommonService,
    public _cf: CustomizationfileService,

  ) {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.darkMode = prefersDark.matches;
    this.active = false;
    setTimeout(() => {
      this.avatar = this._cf.getavatar();
      this.profile = this._cf.getprofile();
      this.active = true;
    }, 2000);
  }

  ngOnInit() { }


  cambio() {
    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.darkMode = !this.darkMode;
    document.body.classList.toggle('dark');

  }



  async logout() {
    const alert = await this.alertController.create({
      header: "¿Cerrar?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Ok",
          handler: () => {
            this._auth.logout();
          }
        }
      ]
    });

    await alert.present();
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: "Albums",
      buttons: [
        {
          text: "English",
          role: "destructive",
          handler: () => {
            this.changeLang("en");
          }
        },
        // {
        //   text: "Italiano",
        //   handler: () => {
        //     this.changeLang("it");
        //   }
        // },
        // {
        //   text: "Portugues",
        //   handler: () => {
        //     this.changeLang("pr");
        //   }
        // },
        {
          text: "Español",
          handler: () => {
            this.changeLang("es");
          }
        },
        {
          text: "Cancelar",
          icon: "close",
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          }
        }
      ]
    });
    await actionSheet.present();
  }

  changeLang(lang) {
    this._language.switchLanguage(lang);
  }
}
