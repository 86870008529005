import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-full-screen-photo',
  templateUrl: './full-screen-photo.component.html',
  styleUrls: ['./full-screen-photo.component.scss'],
})
export class FullScreenPhotoComponent implements OnInit {
  public item: any;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() { 
    console.log('this.item', this.item);
  }



  /**
* 
* @param photoBlob 
*/
  closeModal() {
    this.modalCtrl.dismiss();
  }
}
