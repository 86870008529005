import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { checkIfEmailDoesExist } from 'src/app/services/auth/authentication.service';
import { AuthenticationService, CommonService } from 'src/app/services/services';

@Component({
  selector: 'app-admin-form-update-user-email',
  templateUrl: './admin-form-update-user-email.component.html',
  styleUrls: ['./admin-form-update-user-email.component.scss'],
})
export class AdminFormUpdateUserEmailComponent implements OnInit {

  @Input() uid: string;
  @Input() email: string = '';

  /** Operation Type */
  @Input() ot: 'local';

  @Output() onUpdated = new Subject<any>();

  public form!: FormGroup;
  public vm = {
    email: [
      { type: 'required', message: 'El correo es requerido' },
      { type: 'pattern', message: 'El correo no es válido' },
      { type: 'emailStored', message: 'El correo ya existe' },
    ]
  };
  public submitted = false;

  constructor(
    private fb: FormBuilder,
    private commonSrv: CommonService,
    private alertCtrl: AlertController,
    private authSrv: AuthenticationService,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  get f() { return this.form.controls; }

  buildForm(){
    this.form = this.fb.group({
      uid: [this.uid],
      email: [this.email, 
        [
          Validators.required,
          Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ],
        [
          checkIfEmailDoesExist(this.authSrv)
        ]
      ],
    });
  }

  async onSubmit(){
    try {

      this.submitted = true;
      if (!this.form.valid) { 
        return; 
      }

      const ask = await this.alertCtrl.create({
        header: 'Confirmación',
        message: '¿Está seguro de que desea cambiar el correo electrónico de este usuario?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel'
          },
          {
            text: 'Aceptar',
            role: 'next'
          }
        ]
      });

      await ask.present();

      const { role } = await ask.onDidDismiss();
      console.log('role', role);
      if(role == 'cancel'){ return; }

      const formData = this.form.value;
      // console.log('Try to update email', formData);

      if(this.ot !== 'local'){
        this.onUpdated.next(this.form.value);
        return;
      }

      await this.commonSrv.presentLoading();

      /** Actualizar Email */
      await this.authSrv.updateUserEmail(this.uid, formData.email);
      this.commonSrv.dismissLoading();
      return;
      
    } catch (err) {
      console.log('Error on AdminFormUpdateUserEmailComponent.onSubmit', err);
      this.commonSrv.dismissLoading();
      return;
    }
  }

}
