import { Injectable } from "@angular/core";
// import { AppVersion } from '@ionic-native/app-version/ngx';

@Injectable({
  providedIn: "root"
})
export class CustomizationfileService {

  versionNumber = "n/a"

  flaglist = [
    {
      name: "Argentina",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FArgentina.mp4?alt=media&token=381f2ae6-6c0d-465c-af3a-78f1cf17555c"
    },
    {
      name: "Australia",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FAustralia.mp4?alt=media&token=644501c2-d974-41d5-b0b5-ffcbea25691c"
    },
    {
      name: "Azerbaijan",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FAzerbaijan.mp4?alt=media&token=794ec7c9-92c8-4593-b3e2-6f6bc431d604"
    },
    {
      name: "Bolivia",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FBolivia.mp4?alt=media&token=a1cacc38-4c73-401b-b789-35e9d4e099e4"
    },
    {
      name: "Bulgaria",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FBulgaria.mp4?alt=media&token=c75be944-43c6-4f65-9a97-c5959f5f0c40"
    },

    {
      name: "Canada",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FCanada.mp4?alt=media&token=65fbd124-7455-469a-827c-0a718866215a"
    },
    {
      name: "Chile",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FChile.mp4?alt=media&token=a3ce9498-da2a-481d-b3ab-91474ad82e0a"
    },
    {
      name: "China",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FChina.mp4?alt=media&token=52223a1e-5690-4d07-812d-4c4b42534ff4"
    },
    {
      name: "Colombia",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FColombia.mp4?alt=media&token=095baacb-2f0a-4351-9aa6-08b08210d862"
    },
    {
      name: "Cuba",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FCuba.mp4?alt=media&token=b52a009a-a12f-4f3b-bc94-544f9d6485f7"
    },
    {
      name: "Cyprus",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FCyprus.mp4?alt=media&token=051aa4d8-88e1-469f-bdce-15709b920e3c"
    },
    {
      name: "Ecuador",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FEcuador.mp4?alt=media&token=8b0fed72-5f90-41ba-bcbd-18840d184617"
    },
    {
      name: "Salvador",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FEl%20Salvador.mp4?alt=media&token=09796a2d-fea6-4394-8b2f-28fc442f823e"
    },
    {
      name: "Estonia",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FEstonia.mp4?alt=media&token=b23e4510-702c-4bbb-b3d7-23ddd9f6bca5"
    },
    {
      name: "France",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FFrance.mp4?alt=media&token=b6abdc3e-3f91-4447-8953-cc1b31e1b50f"
    },
    {
      name: "Georgia",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FGeorgia.mp4?alt=media&token=875ec90a-fe41-4e6b-b488-4ac5cf8552ee"
    },
    {
      name: "Germany",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FGermany.mp4?alt=media&token=26df0d02-88ad-4298-9af6-751901c918c8"
    },
    {
      name: "Guatemala",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FGuatemala.mp4?alt=media&token=57da8a6b-bcc1-4124-acd7-0efbcc872d68"
    },
    {
      name: "Hungary",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FHungary.mp4?alt=media&token=6df1ce9b-aad9-4c13-8c1c-df35a8b052c8"
    },
    {
      name: "India",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FIndia.mp4?alt=media&token=5c00127a-0099-48ae-b1e3-45ee1867d285"
    },
    {
      name: "Israel",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FIsrael.mp4?alt=media&token=8656f267-98b6-4e0a-96e4-0927fe3d7a91"
    },
    {
      name: "Italy",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FItaly.mp4?alt=media&token=c6c96be4-1a34-42fa-b632-f46a59bf7836"
    },
    {
      name: "Japan",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FJapan.mp4?alt=media&token=7959c09e-32c2-4a02-a2dc-67a6bc043315"
    },
    {
      name: "Lithuania",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FLithuania.mp4?alt=media&token=3d3b8efa-de78-4610-adc0-aa8639697ece"
    },
    {
      name: "Mexico",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FMexico.mp4?alt=media&token=2ca53723-4c33-4b2e-884d-a72f05066fb4"
    },
    {
      name: "Monaco",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FMonaco.mp4?alt=media&token=97254a5c-e66c-42f3-a165-9c46f83fdd88"
    },
    {
      name: "Myanmar",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FMyanmar.mp4?alt=media&token=5a3df8c1-6c1c-46a7-8b1f-3c6416620f25"
    },
    {
      name: "Panama",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FPanama.mp4?alt=media&token=cc3fced4-4a46-43c0-81a4-d5f5a295ca6c"
    },
    {
      name: "Peru",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FPeru.mp4?alt=media&token=02308474-dad4-40dc-8c9b-e477f5daeb21"
    },
    {
      name: "Philippines",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FPhilippines.mp4?alt=media&token=5d7e2501-8111-4eaf-aa34-0fd173e85f18"
    },
    {
      name: "Romania",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FRomania.mp4?alt=media&token=c99dd201-580e-4ede-a4c2-9ca4ff321fff"
    },
    {
      name: "Russia",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FRussia.mp4?alt=media&token=3952d4e4-0954-4c5a-8474-19f78abd3640"
    },
    {
      name: "Serbia",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FSerbia.mp4?alt=media&token=8f4f138c-8343-4c2b-afe0-d6d94c5198f1"
    },
    {
      name: "%2FSouth%20Africa",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FSouth%20Africa.mp4?alt=media&token=c0311b37-a0ac-4081-a13a-fdd6f01cd1d1"
    },
    {
      name: "Spain",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FSpain.mp4?alt=media&token=59ff13b5-333d-44cf-9d0d-1356f0e8fe0b"
    },
    {
      name: "Sweden",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FSweden.mp4?alt=media&token=664a2249-fda4-4974-b399-6d09dc6e01a1"
    },
    {
      name: "Switzerland",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FSwitzerland.mp4?alt=media&token=64bfa36d-f269-4007-8ff5-13b9c0784737"
    },
    {
      name: "United%20Kingdom",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FUnited%20Kingdom.mp4?alt=media&token=962b5e1b-7fe3-4f44-8662-154bccc44f16"
    },
    {
      name: "United States",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FUnited%20States%20Minor%20Outlying%20Islands.mp4?alt=media&token=754b27be-aeb0-48d2-a6eb-c3953c18b8d3"
    },
    {
      name: "Uruguy",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FUruguy.mp4?alt=media&token=b262beb4-c5f0-4549-bf9b-d2c37d0a73df"
    },
    {
      name: "Vanuatu",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FVanuatu.mp4?alt=media&token=8d90d08b-8bf1-420a-8604-21fb58944f4d"
    },
    {
      name: "Venezuela",
      url: "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/Flag%2FVenezuela.mp4?alt=media&token=5bc11757-290c-4082-84e7-4367ecbb77fa"
    }
  ]

  constructor() {
  }
  getVersion() {
    return "0.0.257"
  }

  getUrlRoot() {
    return `eventDays/${this.getKeyDb()}`;
  }

  getKeyInfo() {
    return window.localStorage.getItem("keyInfo");
  }

  getKeyDb() {
    return window.localStorage.getItem("keyDB");
  }

  getUid() {
    return window.localStorage.getItem("uid");
  }

  getName() {
    return window.localStorage.getItem("nameDB")
  }


  getDayConf() {
    return window.localStorage.getItem("dayConf")
  }

  getStageConf() {
    return window.localStorage.getItem("stageConf")
  }

  getStageNameConf() {
    return window.localStorage.getItem("name_day_conf")
  }


  getrol() {
    return JSON.parse(window.localStorage.getItem("rol"));
  }

  getavatar() {
    return window.localStorage.getItem("avatar");
  }

  gettokenPush() {
    return window.localStorage.getItem("tokenPush");
  }

  getprofile() {
    return JSON.parse(window.localStorage.getItem("profile"));
  }
}
