import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { HttpClient } from "@angular/common/http";
import { AngularFirestore } from '@angular/fire/firestore';
import { CommonService } from "../services/common/common.service";
import { environment } from "src/environments/environment";


const URL_ROOT = environment.urlrootFunctions

@Injectable()
export class RegisteredUsersService {
  auth: any;
  usersRef: AngularFireObject<any>;
  usersRefidentification: AngularFireObject<any>;
  usersRefRol: AngularFireObject<any>;
  constructor(
    public afs: AngularFirestore,
    public db: AngularFireDatabase,
    private http: HttpClient,
  ) { }


  /**
   * 
   * @param user 
   * @returns 
   */
  createUser(user): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(`${URL_ROOT}/manage_users/createUser`);
      this.http
        .post(`${URL_ROOT}/manage_users/createUser`, user)
        .subscribe(
          (res) => {
            console.log(res);
            resolve(res);
          },
          (err) => {
            console.log(err);
            reject(err.error);
          }
        );
    });
  }


  /**
   * 
   * @param user 
   * @returns 
   */
  changePassword(user) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${URL_ROOT}/manage_users/changePasswordUser`,
          user
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }


 


  /**
   * 
   * @param user 
   * @returns 
   */
  changeEmail(user) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${URL_ROOT}/manage_users/updateEmail`, user)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }


  /**
   * 
   * @param uid 
   * @param status 
   */
  statusUser(uid, status) {
    // status
    // 0 = dasabilitado
    // 1 = habilitado
    this.usersRef = this.db.object(`users/${uid}`);
    this.usersRef.update({ status: status });
  }


  /**
   * 
   * @param uid 
   * @returns 
   */
  deleteUser(uid): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${URL_ROOT}/manage_users/deleteUser`, { uid: uid })
        .subscribe(async (res) => {
          await this.deleteNodoUser(uid);
          resolve(res);
        }, (err) => {
          reject(err.error);
        }
        );
    });
  }

  /**
   * 
   * @param uid 
   */
  async deleteNodoUser(uid) {
    console.log("uid", uid)
    await this.db.object(`users/${uid}`).remove()
    await this.afs.collection('profile').doc(uid).delete()
    await this.afs.collection('users').doc(uid).delete()

  }


  /**
   * 
   * @param uid 
   * @param data 
   */
  async updateUser(uid, data) {
    // const user = {
    //   name: this._cs.dedupe(data.name, ""),
    //   surnames: this._cs.dedupe(data.surnames, ""),
    //   email: data.email,
    //   phone: data.phone,
    //   celular: data.celular,
    //   birthdate: data.birthdate,
    //   identificationNumber: data.identification,
    // };

    // this.usersRef = this.db.object(`users/${uid}/profile`);
    // this.usersRef.update(user);

    // this.userdateUserIdentification(uid, data.identification);

    // this.userdateUserRol(uid, data.rol);
  }


  /**
   * 
   * @param uid 
   * @param data 
   */
  async updateProfile_(uid, data) {
    var fields = data.phone.split("-");
    let id = data.identification ? data.identification : "admin";

    const user = {
      name: data.name,
      surnames: data.surnames,
      email: data.email,
      phone: fields[0],
      celular: fields[0],
      birthdate: data.birthdate,
      identificationNumber: id,
    };
    this.usersRef = this.db.object(`users/${uid}/profile`);
    await this.usersRef.update(user);

    //  ID
    this.userdateUserIdentification(uid, data.identification);

    // ROl
    this.userdateUserRol(uid, data.rol);
  }


  /**
   * 
   * @param uid 
   * @param identification 
   */
  userdateUserIdentification(uid, identification) {
    this.usersRefidentification = this.db.object(`users/${uid}`);
    this.usersRefidentification.update({ identification: identification });
  }

  /**
   * 
   * @param uid 
   * @param rol 
   */
  userdateUserRol(uid, rol) {
    this.db.object(`users/${uid}/rol`).set(rol);
    const eventKey = window.localStorage.getItem("keyDB");
    console.log(`eventRoles/${eventKey}/${rol}/${uid}`);
    this.db.object(`eventRoles/${eventKey}/${rol}/${uid}`).set(rol);
  }
}
