import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'backstageShow'
})
export class BackstageShowPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.filter((obj) => {
      return obj.show_backstage == args
    });
  }

}
