import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'backstage'
})
export class BackstagePipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return value.filter((obj) => {
        return obj.backstage === undefined || false
      });
    }

  }

}
