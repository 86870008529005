import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "orderResults"
})
export class OrderResultsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return;
    value.sort((a, b) => {
      if (a.finalScore < b.finalScore) {
        return 1;
      }
      if (a.finalScore > b.finalScore) {
        return -1;
      }
      return 0;
    });
    return value;
  }
}
