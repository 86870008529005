import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "../common/common.service";
@Injectable({
  providedIn: "root"
})
export class NotificationService {
  constructor(private http: HttpClient, public _cs: CommonService) { }



  createNotification(data: any) {
    return this.http.post(`${this._cs.urlrootFunctions}/onesignal/createNotification`, data)
  }
}
