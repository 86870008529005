import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { CustomizationfileService } from "../customizationfile/customizationfile.service";

export interface MyData {
  name: string;
  filepath: string;
  size: number;
}

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  // Upload Task 
  task: AngularFireUploadTask;

  // Progress in percentage
  percentage: Observable<number>;

  // Snapshot of uploading file
  snapshot: Observable<any>;

  // Uploaded File URL
  UploadedFileURL: Observable<string>;

  //Uploaded Image List
  images: Observable<MyData[]>;

  //File details  
  fileName: string;
  fileSize: number;

  //Status check 
  isUploading: boolean;
  isUploaded: boolean;
  data_music: any

  private musicCollection: AngularFirestoreCollection<MyData>;

  constructor(
    private storage: AngularFireStorage,
    private database: AngularFirestore,
    public _cf: CustomizationfileService) {
    this.isUploading = false;
    this.isUploaded = false;

    //Set collection where our documents/ images info will save
    if (this._cf.getKeyDb()) {
      this.musicCollection = database.collection('event_music').doc(this._cf.getKeyDb()).collection<MyData>('music_list')
      this.images = this.musicCollection.valueChanges();
    }
  }

  /**
   * 
   * @param event 
   * @returns 
   */
  uploadFile(event: FileList) {
    return new Promise(async (resolve, reject) => {

      try {
        const file = event.item(0)
        console.log("file", file)
        console.log('Validation', file.type.split('/')[0])
        // Validation for Images Only
        this.isUploading = true;
        this.isUploaded = false;

        this.fileName = file.name;
        console.log("fileName", this.fileName)

        // The storage path
        const path = `music/${this._cf.getKeyDb()}/${new Date().getTime()}_${file.name.trim()}`;
        console.log("path", path)

        // Totally optional metadata
        const customMetadata = { app: `Music Upload Events ${this._cf.getName()}` };

        //File reference
        const fileRef = this.storage.ref(path);

        // The main task
        this.task = this.storage.upload(path, file, { customMetadata });

        // Get file progress percentage
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges().pipe(
          finalize(() => {
            // Get uploaded file storage path
            this.UploadedFileURL = fileRef.getDownloadURL();
            this.UploadedFileURL.subscribe(resp => {
              this.isUploading = false;
              this.isUploaded = true;
              resolve(resp)
            }, err => {
              reject(err)
            })
          }), tap(snap => {
            this.fileSize = snap.totalBytes;
            console.log("fileSize", this.fileSize)
          })
        )
      } catch (error) {
        reject(error)
      }
    })
  }


  /**
   * no tocar 
   * @param event 
   * @returns 
   */
  uploadFile2(event: FileList) {
    return new Promise(async (resolve, reject) => {

      try {
        const file = event.item(0)
        console.log("file", file)
        console.log('Validation', file.type.split('/')[0])
        // Validation for Images Only



        this.isUploading = true;
        this.isUploaded = false;


        this.fileName = file.name;
        console.log("fileName", this.fileName)

        // The storage path
        const path = `music/${this._cf.getKeyDb()}/${new Date().getTime()}_${file.name.trim()}`;
        console.log("path", path)

        // Totally optional metadata
        const customMetadata = { app: `Music Upload Events ${this._cf.getName()}` };

        //File reference
        const fileRef = this.storage.ref(path);

        // The main task
        this.task = this.storage.upload(path, file, { customMetadata });

        // Get file progress percentage
        this.task.snapshotChanges().pipe(
          finalize(async () => {
            console.log("snapshotChanges")
            // Get uploaded file storage path
            const musicUrl = await fileRef.getDownloadURL().toPromise();
            console.log("musicUrl", musicUrl)
            resolve(musicUrl)
            // this.UploadedFileURL = fileRef.getDownloadURL();
            // this.UploadedFileURL.subscribe(resp => {
            //   this.isUploading = false;
            //   this.isUploaded = true;
            //   console.log("resp", resp)

            //   resolve(resp)
            // }, err => {
            //   reject(err)
            // })
          }))
      } catch (error) {
        console.log("error", error)
        reject(error)
      }
    })
  }

  addImagetoDB(music: MyData) {
    //Create an ID for document
    const id = this.database.createId();
    this.data_music = music
    // Set document id with value in database
    this.musicCollection.doc(id).set(music).then(resp => {
      console.log("music", resp);
    }).catch(error => {
      console.log("error " + error);
    });
  }

  async removeByURL(url: string) {
    try {
      console.log("url", url)
      await this.storage.storage.refFromURL(url).delete();
      return true;

    } catch (err) {
      console.log('Error on UploadFileService removeByURL', err);
      return false;
    }
  }

  /**
 *
 * @param event upload file document
 * @returns
 */
  async uploadFileDocument(event) {
    return new Promise(async (resolve, reject) => {
      const file = event;
      console.log("file", file);
      console.log("Validation", file.type.split("/")[0]);
      try {
        this.fileName = file.name;
        console.log("fileName", this.fileName);

        // The storage path
        const filePath = `documents/${this._cf.getUid()}/${file.name.trim()}`;
        console.log("path", filePath);

        const fileRef = this.storage.ref(filePath);

        // const task = fileRef.putString(event, "data_url");
        const task = this.storage.upload(filePath, file);

        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                let uploadProgress = 0;
                resolve(url);
              });
            })
          )
          .subscribe();
      } catch (err) {
        reject(err);
      }
    });
    // The File object
  }

  async uploadFileDocumentIntoRoute(filePath: string, file: File) {
    return new Promise(async (resolve, reject) => {
      try {

        /** Crear Referencia en el Bucket */
        const fileRef = this.storage.ref(filePath);

        /** Crear tarea para subir archivp */
        const task = this.storage.upload(filePath, file);

        /** 
         * Ejecutar tarea y esperar respuesta 
         * - Retorna la URL del archivo subido
         */
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                let uploadProgress = 0;
                resolve(url);
              });
            })
          )
          .subscribe();

      } catch (err) {
        return reject(err);
      }
    });
  }

}
