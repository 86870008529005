import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-edit-results',
  templateUrl: './edit-results.component.html',
  styleUrls: ['./edit-results.component.scss'],
})
export class EditResultsComponent implements OnInit {
  details: any;
  type: any;
  ratingJustification: any;
  constructor(private modalCtrl: ModalController,
    private params: NavParams,
    public alertController: AlertController) {
    this.details = this.params.get("details");
    this.type = this.params.get("type");

    console.log('app-edit-results', this.details);
    console.log('app-edit-results', this.type);

  }

  ngOnInit() { }

  async presentAlertPrompt() {
    const alert = await this.alertController.create({
      header: '¿Por qué quieres cambiar esta calificación?',
      inputs: [
        {
          name: 'ratingJustification',
          id: 'ratingJustification',
          type: 'textarea',
          placeholder: 'justificación de calificación'
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
            this.saveChanges(false)
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            console.log('Confirm Ok', data);
            if (!data.ratingJustification) { return }
            this.ratingJustification = data.ratingJustification
            this.saveChanges(true)
          }
        }
      ]
    });

    await alert.present();
  }


  /**
   * @dev saveChanges
   * @param update 
   */
  saveChanges(update) {
    this.modalCtrl
      .dismiss({
        ratings: this.details.ratings,
        ratingJustification: this.ratingJustification
      } || {},
        update);
  }

}
