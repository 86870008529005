import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "keys"
})
export class KeysPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return;
    let keys = [];
    for (let key in value) {
      keys.push(Object.assign(value[key], { key_DB: key }));
    }
    return keys;
  }
}
