// https://stackoverflow.com/questions/52140850/firebase-isnt-initialised-on-ios-ionic

import { ToastController } from '@ionic/angular';
import { Injectable } from "@angular/core";
import { Firebase } from "@ionic-native/firebase/ngx";
import { Platform } from "@ionic/angular";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from "@angular/fire/database";

@Injectable({
  providedIn: "root"
})
export class FcmService {
  constructor(
    private firebase: Firebase,
    private afs: AngularFirestore,
    private platform: Platform,
    public db: AngularFireDatabase,
    public toastController: ToastController
  ) { }


  async getToken() {
    let token;

    if (this.platform.is('android')) {
      token = await this.firebase.getToken();
    }

    if (this.platform.is('ios')) {
      token = await this.firebase.getToken();
      await this.firebase.grantPermission();
    }

    this.saveToken(token);
  }

  private async saveToken(token) {
    if (token) {
      const uid = localStorage.getItem("uid");
      const data = {
        token,
        userId: uid
      };

      const devicesRef = this.afs.collection("devices");
      this.setTokenRegistration(uid, token);
      localStorage.setItem("tokenPush", token);
      return devicesRef.doc(token).set(data);
    }

  }

  setTokenRegistration(uid, token) {
    if (uid && token) {
      const url = `users/${uid}`;
      const tokenPhoneRef = this.db.object(url);
      tokenPhoneRef.update({ tokenPhone: token });
    }
  }

  onNotifications() {
    return this.firebase.onNotificationOpen();
  }

  async showToast(err) {
    const toast = await this.toastController.create({
      message: `"There was an error: " - ${err}`,
      duration: 5000
    });
    toast.present();
  }



}
