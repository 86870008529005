import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Pipe({
  name: 'categoryCheck'
})
export class CategoryCheckPipe implements PipeTransform {

  constructor(public db: AngularFireDatabase) { }

  /**
   * 
   * @param KeyDb 
   * @param day 
   * @param code 
   * @returns 
   */
  async transform(KeyDb: string, day: string, code: string): Promise<any> {
    const isCheck: any = await this.getCheckedCategories(KeyDb, day, code);
    if (!isCheck) return null;


    return `${isCheck.count} - Calificaciones - ${new Date(isCheck.date)}`;
  }


  /**
   * 
   * @param KeyDb 
   * @param day 
   * @param code 
   * @returns 
   */
  getCheckedCategories(KeyDb: string, day: string, code: string) {
    return new Promise((resolve, reject) => {
      this.db.object(`/checkRatings/${KeyDb}/${day}/${code}`)
        .valueChanges()
        .subscribe((data: any) => {
          resolve(data)
        })
    })
  }

}
