import { Injectable } from '@angular/core';
import { CustomizationfileService } from "../customizationfile/customizationfile.service";
import { AngularFireDatabase } from "@angular/fire/database";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  rol = 0
  constructor(public _cf: CustomizationfileService, public db: AngularFireDatabase, ) {
  }

  valid(rol) {
    const rolUser = this._cf.getrol()
    if (rolUser == 1) {
      return true
    } else if (rol == rolUser) {
      return true
    } else {
      return false
    }
  }


  eventRol(rol) {
    // eventRoles/-LeNPs2i1iGB5aCaKTmU/1/IgxDYZRevoczDIpob2rb5TgX1pH3
    const keydb = this._cf.getKeyDb()
    const uid = this._cf.getUid()
    return new Promise(resolve => {
      console.log(`eventRoles/${keydb}/${rol}/${uid}`);
      this.db
        .object(`eventRoles/${keydb}/${rol}/${uid}`)
        .valueChanges()
        .subscribe((rol: any) => {
          resolve(rol);
        });
    });
  }

  isSuperAdmin() {
    return new Promise(resolve => {
      const uid = this._cf.getUid()
      const url = `/users/${uid}/super-admin`;
      this.db
        .object(url)
        .valueChanges()
        .subscribe((rol: any) => {
          resolve(rol);
        });
    });
  }
}
