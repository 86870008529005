import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { CustomizationfileService } from 'src/app/services/customizationfile/customizationfile.service';
import { CommonService } from 'src/app/services/services';

@Pipe({
  name: 'cloudResults'
})
export class CloudResultsPipe implements PipeTransform {

  constructor(
    public _cs: CommonService,
    public db: AngularFireDatabase,
    public _cf: CustomizationfileService) { }


  /**
   * 
   * @param value 
   * @param day 
   * @param code 
   * @param eventDays 
   * @param classified 
   * @param cloudResultsEnable 
   * @returns 
   */
  transform(value: any, day: any, code: any, eventDays: any, classified: any, cloudResultsEnable: any): any {
    if (!value
      || !day
      || !code
      || !eventDays
      || !classified
      || !cloudResultsEnable) {
      return value;
    }
    this.cloudResults(eventDays, day, code, value, classified, cloudResultsEnable)
    return value;

  }

  /**
   * 
   * @param eventDays 
   * @param day 
   * @param code 
   * @param value 
   * @param classified 
   * @returns 
   */
  async cloudResults(eventDays, day, code, value, classified, cloudResultsEnable) {


    try {
      const keydb = this._cf.getKeyDb()

      await this._cs.presentLoading()

      /// @dev si NO estan los resultados en la nube no se actualiza
      console.log("existEventDays", `eventDays/${keydb}/${eventDays}/${code}/categories`)
      const eventDaysEnable = await this.existEventDays(`eventDays/${keydb}/${eventDays}/${code}/categories`)
      if (!eventDaysEnable) {
        console.warn("eventDaysEnable", eventDaysEnable)
        this._cs.presentToast("No se puede actualizar, no hay resultados en la nube")
        return
      }


      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        const post = index + 1
        console.log(element.name, post)

        const key = element.key || element.key2 || element.keyFV || element.keyStatus || element.name

        /// @dev actualiza posicion final
        await this.db
          .list(`/globalQualificationRecord/${keydb}/${day}/${code}/participation`)
          .update(key, {
            final_position: post,
            verified: Date.now(),
            admin: this._cf.getUid(),
          });

        const eventDaysRef = this.db.list(`eventDays/${keydb}/${eventDays}/${code}/categories`);
        console.log("eventDaysRef", `eventDays/${keydb}/${eventDays}/${code}/categories`)

        if (eventDaysEnable && post > classified) {
          console.error("elimninado", element.name, post, classified)
          console.log("key", key)
          await eventDaysRef.update(key, {
            statusScreen: 13,
            verified: Date.now(),
            admin: this._cf.getUid(),
            currentPosition: post,
            validated: false,
            backstageStatus: "",
            assignNumber: null,
            assignRound: null,
            dancingStatus: "",
            confirm: false,
          });
        } else {
          console.log("statusScreen", element.name, post, classified)
          await eventDaysRef.update(key, {
            statusScreen: 0,
            orden_calendar: 0,
            verified: Date.now(),
            admin: this._cf.getUid(),
            currentPosition: post,
            assignNumber: null,
            assignRound: null,
            validated: false,
            backstageStatus: "",
            dancingStatus: "",
            confirm: false,
          });
        }

        console.log("checkRatings", `checkRatings/${this._cf.getKeyDb()}/${day}/${code}`)
        await this.db
          .object(`checkRatings/${this._cf.getKeyDb()}/${day}/${code}`)
          .set({
            date: new Date().getTime(),
            enable: cloudResultsEnable,
            count: eventDays.length
          })

        return this._cs.presentToast("Actualizado")

      }
    } catch (error) {
      this._cs.presentAlert("Error al actualizar")
      console.error(error)
      return
    } finally {
      this._cs.dismissLoading()
    }
  }



  /**
   * 
   * @param url 
   * @returns 
   */
  existEventDays(url) {
    console.log("existEventDays", url)
    return new Promise(resolve => {
      if (!url) resolve(false)
      this.db.object(url)
        .snapshotChanges()
        .subscribe(action => {
          resolve(action.payload.exists())
        });
    })
  }

}
