import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PersistenceDayAndStageService {

  public day: any = [
    {
      name: "Day 1",
      label: "",
      value: "day1",
      checked: false,
      type: "radio"
    },
    {
      name: "Day 2",
      label: "Wednesday 12th December 🕕",
      value: "day2",
      checked: false,
      type: "radio"
    },
    {
      name: "Day 3",
      label: "Thursday 13th December 🕕",
      value: "day3",
      checked: false,
      type: "radio"
    },
    {
      name: "Day 4",
      label: "Friday 14th December 🕕",
      value: "day4",
      checked: false,
      type: "radio"
    },
    {
      name: "Day 5",
      label: "Saturday 15th December 🕕",
      value: "day5",
      checked: false,
      type: "radio"
    },
    {
      name: "Day 6",
      label: "Sunday 16th December 🕕",
      value: "day6",
      checked: false,
      type: "radio"
    }
  ];

  public alertInputs: any = [
    {
      name: 'radio1',
      type: 'radio',
      label: 'Day 1',
      value: 'day1',
      checked: true
    },
    {
      name: 'radio2',
      type: 'radio',
      label: 'Day 2',
      value: 'day2',
    },
    {
      name: 'radio3',
      type: 'radio',
      label: 'Day 3',
      value: 'day3',
    },
    {
      name: 'radio4',
      type: 'radio',
      label: 'Day 4',
      value: 'day4',
    },
    {
      name: 'radio5',
      type: 'radio',
      label: 'Day 5',
      value: 'day5',
    },
    {
      name: 'radio6',
      type: 'radio',
      label: 'Day 6',
      value: 'day6',
    }
  ]

  public stageInput: any = [
    {
      name: 'radio1',
      type: 'radio',
      label: 'Stage 1',
      value: '1',
      checked: true
    },
    {
      name: 'radio2',
      type: 'radio',
      label: 'Stage 2',
      value: '2',
    },
    {
      name: 'radio3',
      type: 'radio',
      label: 'Stage 3',
      value: '3',
    },
    {
      name: 'radio4',
      type: 'radio',
      label: 'Stage 4',
      value: '4',
    },
    {
      name: 'radio5',
      type: 'radio',
      label: 'Stage 5',
      value: '5',
    },
    {
      name: 'radio6',
      type: 'radio',
      label: 'Stage 6',
      value: '6',
    }
  ]

  constructor() { }


  set(day, stage, value) {
    let obj = {
      day: day,
      stage: stage,
    }
    localStorage.setItem(value, JSON.stringify(obj))
  }

  get(value) {
    return JSON.parse(localStorage.getItem(value))
  }



}
