import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import SignaturePad from 'signature_pad';
import { CommonService } from 'src/app/services/services';

@Component({
  selector: 'app-signature-silleteros',
  templateUrl: './signature-silleteros.component.html',
  styleUrls: ['./signature-silleteros.component.scss'],
})
export class SignatureSilleterosComponent implements AfterViewInit {

  public item: any;
  public msn: any;
  public isValid: any;
  public restrictions: any;
  public viewRestrictions = false;
  signatureNeeded!: boolean;
  // signaturePad!: SignaturePad;
  @ViewChild('canvas', { static: true }) canvasEl!: ElementRef;
  signatureImg!: string;

  constructor(
    private commonSrv: CommonService,
    private modalCtrl: ModalController) { }

  ngOnInit(): void {
    console.log('this.restrictions', this.restrictions);
    console.log('this.item', this.item);
  }


  changeSegment() {
    this.viewRestrictions = !this.viewRestrictions
  }

  ngAfterViewInit() {
    // this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    // this.signaturePad.clear();
  }

  savePad() {
    // const base64Data = this.signaturePad.toDataURL();
    // this.signatureImg = base64Data;
    // console.log('signatureImg', this.signatureImg);
    // this.signatureNeeded = this.signaturePad.isEmpty();
    // if (this.signatureNeeded) {
    //   this.signatureNeeded = false;
    //   this.commonSrv.presentToast('Firma requerida');
    //   return
    // }

    // this.closeModal(this.signatureImg);
  }


  /**
   * 
   * @param signatureImg 
   */
  async closeModal(signatureImg) {
    this.modalCtrl.dismiss({
      'signatureImg': signatureImg || null,
    });
  }

  /**
   * 
   * @param inputString 
   * @returns 
   */
  removeSpecialCharacters(inputString) {
    if (!inputString) return '';
    // Expresión regular para filtrar caracteres especiales
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g;

    // Reemplazar caracteres especiales con una cadena vacía
    return inputString.replace(regex, '');
  }


}
