import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "../common/common.service";
import { environment } from "../../../environments/environment";

const url_root = environment.urlrootFunctions

@Injectable({
    providedIn: "root"
})
export class NewBackendService {

    constructor(private http: HttpClient, public _cs: CommonService) { }



    checkInAutomaticAccreditations(
        keydb,
        day,
        uid
    ) {
        return new Promise((resolve, reject) => {
            this.http.get(`${url_root}/accreditations/check-in/?keyDb=${keydb}&day=${day}&uid=${uid}`)
                .subscribe(res => {
                    resolve(res);
                }, err => {
                    reject(err);
                });
        });
    }




    checkInAutomatic(data) {
        return new Promise((resolve, reject) => {
            this.http.post(`${url_root}/check-in-automatic/admin`, data)
                .subscribe(res => {
                    resolve(res);
                }, err => {
                    reject(err);
                });
        });
    }


    sendAllChenkIn(data) {
        return new Promise((resolve, reject) => {
            this.http.post(`${url_root}/check-in-automatic/send-all`, data)
                .subscribe(res => {
                    resolve(res);
                }, err => {
                    reject(err);
                });
        });
    }

    sendAllBackstage(data) {
        return new Promise((resolve, reject) => {
            this.http.post(`${url_root}/backstage/sendAll`, data)
                .subscribe(res => {
                    resolve(res);
                }, err => {
                    reject(err);
                });
        });
    }


    downloadMusic(url, data) {
        return new Promise((resolve, reject) => {
            console.log(1, `${url_root}/${url}`)
            this.http.post(`${url_root}/${url}`, data, {
                responseType: 'arraybuffer'
            }).subscribe(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    backstageCallNotification(data) {
        return new Promise((resolve, reject) => {
            this.http.post(`${url_root}/onesignal/backstage-call-notification`, data)
                .subscribe(res => {
                    resolve(res);
                }, err => {
                    reject(err);
                });
        });
    }



    /**
     * 
     * @param data 
     * @returns 
     */
    getOfficiaListOfDepartures(data) {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${url_root}/official_list_of_departures`, data)
                .subscribe(res => { resolve(res); }, err => { reject(err); });
        });
    }


    /**
     * 
     * @param data 
     * @returns 
     */
    getOfficiaListOfDeparturesUser(data) {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${url_root}/official_list_of_departures/user`, data)
                .subscribe(res => { resolve(res); }, err => { reject(err); });
        });
    }


    /**
     * 
     * @param data 
     * @returns 
     */
    getOfficiaListOfDeparturesDownloadAll(data) {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${url_root}/download/officialListOfDepartures/all`, data)
                .subscribe(res => { resolve(res); }, err => { reject(err); });
        });
    }


    /**
     * 
     * @param data 
     * @returns 
     */
    getOfficiaListOfDeparturesDownloadUser(data) {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${url_root}/download/officialListOfDepartures/user`, data)
                .subscribe(res => { resolve(res); }, err => { reject(err); });
        });
    }

}
