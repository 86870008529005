import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "./guard/auth-guard.service";
import { AuthGuard } from "./guard/auth.guard";
import { CheckEventKey } from "./guard/eventKey.guard.service";

const routes: Routes = [
  { path: "", redirectTo: "welcome", pathMatch: "full" },
  {
    path: "welcome",
    loadChildren: () => import('./public/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: "login",
    canActivate: [AuthGuard, CheckEventKey],
    loadChildren: () => import('./public/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: "sign-up",
    canActivate: [AuthGuard, CheckEventKey],
    loadChildren: () => import('./public/sign-up/sign-up.module').then(m => m.SignUp2PageModule)
  },
  {
    path: "members",
    loadChildren: () => import('./members/member-routing.module').then(m => m.MemberRoutingModule),
    canActivate: [AuthGuardService, CheckEventKey]
  },
  {
    path: 'admin-global',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./admin-global/admin-global.module').then(m => m.AdminGlobalPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
