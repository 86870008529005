import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mildSeriousError"
})
export class MildSeriousErrorPipe implements PipeTransform {

  /**
   * 
   * @param value 
   * @returns 
   */
  transform(value: any): any {
    if (!value) return [];
    let result = [];
    value.forEach(x => {
      let milderrors = 0;
      let seriousErrors = 0;
      x.error_;
      x.error_.forEach(e => {
        if (e.error.milderrors) {
          e.error.milderrors.forEach(me => {
            if (me.selected) {
              milderrors += me.value;
            }
          });
        } else {
          milderrors = 0;
        }

        if (e.error.seriousErrors) {
          e.error.seriousErrors.forEach(se => {
            if (se.selected) {
              seriousErrors += se.value;
            }
          });
        } else {
          seriousErrors = 0;
        }
      });
      result.push(
        Object.assign(
          x,
          { milderrors: milderrors },
          { seriousErrors: seriousErrors }
        )
      );
    });

    return result;
  }
}
