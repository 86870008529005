import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBackstage'
})
export class SortBackstagePipe implements PipeTransform {


  /**
   * 
   * @param value 
   * @returns 
   */
  transform(value: any): any {
    if (!value) { return [] }

    return value.sort((x, y) => {
      let xx = x.backstage ? x.backstage : false
      let yy = y.backstage ? y.backstage : false
      // true values first
      return (xx === yy) ? 0 : x ? -1 : 1;
      // false values first
      //return (xx === yy) ? 0 : x ? 1 : -1;
    }).sort((x, y) => {
      let xx = x.assignNumber != undefined ? false : true
      let yy = y.assignNumber != undefined ? false : true
      // true values first
      return (xx === yy) ? 0 : x ? -1 : 1;
    }).sort((x, y) => {
      return x.orden_calendar - y.orden_calendar
    });

  }

}
