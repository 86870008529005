import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot } from "@angular/router";
import { AuthenticationService } from "../auth/authentication.service";
@Injectable({
  providedIn: "root"
})
export class RolService {
  constructor(public auth: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let rol;
    const expectedRole = route.data.expectedRole;
    const authRole = this.auth.role;
    if (authRole == expectedRole || authRole == 1) {
      rol = true;
    } else {
      rol = false;
    }
    return rol;
  }
}
