import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callBackstage'
})
export class CallBackstagePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    console.log(value)
    if (!value) return
    return value.filter(word => !word.backstage);

  }

}
