import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class LanguageService {

  
  constructor(private translate: TranslateService, private http: HttpClient) {
    translate.setDefaultLang("es");
  }



  switchLanguage(language: string) {
    this.translate.use(language);
  }



  /**
   * 
   * @param lang 
   * @returns 
   */
  getJsonLanguage(lang: string): Observable<any> {
    console.log("getJsonLanguage", `${environment.urlrootFunctions}/v2/languages/language?lang=${lang}`);
    return this.http.get(`${environment.urlrootFunctions}/v2/languages/language?lang=${lang}`)
      .pipe(map((response: JSON) => {
        return response;
      }))
  }
}
