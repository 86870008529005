import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
@Injectable({
  providedIn: "root"
})
export class RatingsService {
  constructor(public db: AngularFireDatabase) { }

  getJudgesItemsDetails(data) {
    let result = [];
    data.forEach(x => {
      this.getProfileJudges(x.uid).then(name => {
        let r = {
          ratings: x.category,
          _uid: x.uid,
          name: name
        };
        result.push(r);
      });
    });
    return result;
  }

  getJudgesErrosDetails(data) {
    let result = [];
    data.forEach(x => {
      this.getProfileJudges(x.uid).then(name => {
        let r = {
          _uid: x.uid,
          milderrors: x.error.milderrors,
          seriousErrors: x.error.seriousErrors,
          name: name
        };
        result.push(r);
      });
    });
    return result;
  }

  getAllcategory(data) {
    if (!data) return [];
    let result = [];
    Object.keys(data).forEach(x => {
      data[x].forEach(c => {
        result.push(c);
      });
    });
    return result;
  }

  geAverage(scoreFinal, nro) {
    let rf = scoreFinal / 10 / nro;
    return rf.toFixed(2);
  }

  getResultFinal(scoreF, value, nro) {
    let rf = ((scoreF / 10) * value) / 10 / nro;
    return rf.toFixed(2);
  }

  filterCategory(value, code) {
    return value.filter((obj) => {
      return obj.block === code
    });
  }

  /**
   * @dev no tocar
   * @param value 
   * @param porcent 
   * @returns 
   */
  getSum(value, porcent) {
    if (value.length == 0) { return 0 }
    // console.log("--------------------");
    // console.log("value", value);
    // console.log("porcent", porcent);
    const sum = value.map(obj => obj.scoreF).reduce((acc, next) => acc + next);
    // console.log("sum", sum);
    const result = sum / value[0]["nro"]
    // console.log("result", result);
    const result2 = Math.floor(result * porcent) / 100;
    // console.log("result2", result2);
    return result2.toFixed(2)
    // console.log("--------------------");

  }


  _toFixed(num) {
    if (!num) return;
    // return num.toFixed(4);
    return num
  }

  calculateItems(nro, score, value) {
    const _nro = nro == undefined ? 1 : nro;
    let tp = 0;
    tp = ((parseFloat(score) / 10 / _nro) * parseFloat(value)) / 10;
    return tp.toFixed(2);
  }


  /**
   * NO TOCAS
   * @param data 
   * @returns 
   */
  addKey(data: any) {
    let result = [];
    if (!data || data == undefined) return [];
    Object.keys(data).forEach(x => {
      result.push(Object.assign({ category: data[x] }, { uid: x }));
    });
    return result;
  }

  addKeyError(data: any) {
    if (data == undefined) return [];
    let result = [];
    Object.keys(data).forEach(x => {
      result.push(
        Object.assign(
          { error: data[x] },
          {
            uid: x
          }
        )
      );
    });
    return result;
  }

  getJudgesError(data) {
    return new Promise(resolve => {
      if (!data) return;
      let result = [];
      Object.keys(data).forEach(async x => {
        await this.getProfileJudges(x).then(name => {
          let r = {
            milderrors: data[x].milderrors,
            seriousErrors: data[x].seriousErrors,
            name: name
          };
          result.push(r);
        });
      });
      resolve(result);
    });
  }

  getJudgesItems(data) {
    return new Promise(resolve => {
      if (!data) return;
      let result = [];
      Object.keys(data).forEach(async x => {
        await this.getProfileJudges(x).then(name => {
          let r = {
            ratings: data[x],
            name: name
          };
          result.push(r);
        });
      });
      resolve(result);
    });
  }

  getProfileJudges(uid) {
    return new Promise(resolve => {
      const url = `/users/${uid}/profile`;
      this.db
        .object(url)
        .valueChanges()
        .subscribe((x: any) => {
          resolve(`${x.name} ${x.surnames}`);
        });
    });
  }

  getNames(name) {
    if (!name) return;
    let u = [];
    let value = this.getNameParse(name);
    value.forEach(x => {
      // console.log("x", x);
      let name
      if (x.name && x.surnames) {
        name = `${x.name.toUpperCase() || ''} ${x.surnames.toUpperCase() || ''}`;
      } else {
        name = `${x.name.toUpperCase() || ''}`;
      }
      u.push(name);
    });
    return u.toString();
  }

  getNameParse(value) {
    let _category = [];
    if (Array.isArray(value)) {
      value.forEach(elem => {
        _category.push(elem);
      });
    } else {
      Object.keys(value).forEach(i => {
        _category.push(value[i]);
      });
    }
    return _category;
  }

  /**
   * 
   * @param data 
   * @param tday 
   * @returns 
   */
  classified(data, tday) {
    const day = this.getDay(tday);
    for (let i = 0; i < data.length; i++) {
      if (data[i].day !== day) {
        return data[i].nro;
      }
    }
  }

  classified2(data, tday) {
    const day = this.getDay(tday);
    for (let i = 0; i < data.length; i++) {
      if (data[i].day !== day) {
        return data[i];
      }
    }
  }


  /**
   * @dev no tocar 
   * @param data 
   * @param dayOld 
   * @returns 
   */
  classified3(arraryConfig: any[], dayOld) {
    console.log("-----------------------");
    console.log("arraryConfig", arraryConfig);
    console.log("dayOld", dayOld);

    /** Obtener posición de dia actual dentro del listado de días */
    const findIndex = arraryConfig
      .sort((a, b) => a.dayNumber - b.dayNumber)
      .findIndex((obj) => obj.dayDB === dayOld);
    console.log("findIndex", findIndex);

    /** Es el ultimo dia */
    const isLast = (findIndex == (arraryConfig.length - 1));
    const nextDayIndex = (!isLast) ? findIndex + 1 : (arraryConfig.length - 1);

    /** Retornar configuración del siguiente día */
    return arraryConfig[nextDayIndex];


  }


  /**
   * @dev no tocar
   * @param arrayConfig 
   * @returns 
   */
  addDay(arrayConfig) {
    const res = arrayConfig.map((obj) => {
      return { ...obj, day: this.getAtributoDay(obj.day), dayNumber: Number(`${obj.day}`.split(' ').pop()) }
    }).sort((a, b) => a.dayNumber - b.dayNumber)

    return res;
  }


  /**
   * 
   * @param day 
   * @returns 
   */
  getAtributoDay(day: string) {
    console.log("getAtributoDay", day);
    if (day == "dia 1") {
      return "day1";
    } else if (day == "dia 2") {
      return "day2";
    } else if (day == "dia 3") {
      return "day3";
    } else if (day == "dia 4") {
      return "day4";
    } else if (day == "dia 5") {
      return "day5";
    } else if (day == "dia 6") {
      return "day6";
    } else if (day == "dia 7") {
      return "day7";
    } else if (day == "dia 8") {
      return "day8";
    } if (day == "dia 9") {
      return "day9";
    } if (day == "dia 10") {
      return "day10";
    }
  }




  /**
   * @dev no tocar
   * @param day 
   * @returns 
   */
  getDayIndex3(day) {
    console.log("getDayIndex3", day);
    if (day) {
      return day.substring(3);
    }
  }

  /**
   *  @dev no tocar
   * @param data 
   * @returns 
   */
  getclassifiedTr3(data) {
    let result = []
    data.forEach(elem => {
      result.push({
        add: elem.add,
        day: elem.day,
        day2: this.getDay(elem.day),
        index_day: this.getDayIndex3(this.getDay(elem.day)),
        name: elem.name,
        nro: elem.nro,
      })
    });

    return result.sort((a, b) => {
      if (a.index_day > b.index_day) {
        return 1;
      }
      if (a.index_day < b.index_day) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });;
  }


  /**
   * 
   * @param day 
   * @returns 
   */
  getDay(day) {
    if (day == "day1") {
      return "dia 1";
    } else if (day == "day2") {
      return "dia 2";
    } else if (day == "day3") {
      return "dia 3";
    } else if (day == "day4") {
      return "dia 4";
    } else if (day == "day5") {
      return "dia 5";
    } else if (day == "day6") {
      return "dia 6";
    } else if (day == "day7") {
      return "dia 7";
    } else if (day == "day8") {
      return "dia 8";
    } else if (day == "day9") {
      return "dia 9";
    } else if (day == "day10") {
      return "dia 9";
    }
  }


  totalScore(value) {
    if (!value) return;
    return value / 10;
  }
}
