import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { CustomizationfileService } from "../../services/customizationfile/customizationfile.service";
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: "root"
})
export class ViewStatusService {
  public _playOff = false;
  public _finalResults = false;
  public _calendar = false;
  public _liveVideo = false;
  public _semifinal = false;
  itemRef: AngularFireObject<any>;
  item: Observable<any>;


  public calendarName = new BehaviorSubject([]);

  constructor(
    public db: AngularFireDatabase,
    public _cf: CustomizationfileService
  ) {
    this.getCalendarTitle();
    this.getCalendarTitle

  }


  /**
   * @description
   */
  competitionListCreated() {
    this.db.object(`_competitionListCreated/${this._cf.getKeyInfo()}/statusRatings`)
      .valueChanges()
      .subscribe((data: any) => {
        if (data) {
          data.forEach((x: any) => {
            if (x.id == 'qualifiers') {
              this._playOff = x.enabled;
            }
            if (x.id == 'final_results') {
              this._finalResults = x.enabled;
            }
            if (x.id == 'calendar') {
              this._calendar = x.enabled;
            }

            if (x.id == 3) {
              this._liveVideo = x.enabled;
            }

            if (x.id == 'semifinal') {
              this._semifinal = x.enabled;
            }
          });
        }
      });
  }


  /**
   * 
   * @param _key 
   * @returns 
   */
  getCalendarTitle(_key?: any) {
    const key = _key ? _key : this._cf.getKeyDb();
    if (!key) { return }
    this.db.object(`/eventMethod/${key}/section_list/calendar_title`)
      .snapshotChanges()
      .subscribe((action) => {
        const data: any = action.payload.val();
        console.log(1, action.payload.exists())
        if (!action.payload.exists()) { return }
        const filteData = data.filter((x: any) => x.enabled == true)
          .map((x: any, index: any) => { return { ...x, day: `day${index + 1}` } });
        this.calendarName.next(filteData);
      });
  }

}
