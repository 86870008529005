import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/services/services';

@Component({
  selector: 'app-custom-input-file',
  templateUrl: './custom-input-file.component.html',
  styleUrls: ['./custom-input-file.component.scss'],
})
export class CustomInputFileComponent implements OnInit {

  // @ViewChild('fileInput') fileInputRef: IonInput;
  @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef<HTMLInputElement>;

  @Input() label: string = "Select a file";

  /** Accept only PDF */
  // @Input() accept: string = "application/pdf";
  @Input() accept: string = ".pdf";
  @Input() uploadBtnText: string = "Upload";
  @Input() submitBtnText: string = "Submit";
  @Input() cancelBtnText: string = "Cancel";
  @Input() clearBtnText: string = "Clear";
  @Input() noSelectFileText: string = "No file selected";

  @Output() onSelectFile = new Subject<File | null>();

  public fileLoaded: boolean = false;
  public formFile: File;

  constructor(public commonService: CommonService) { }

  ngOnInit() { }

  get fileName(): string | null {
    return this.formFile ? this.formFile.name : null;
  }

  openFileSelection() { this.fileInputRef.nativeElement.click(); }

  onFileSelected() {


    this.fileLoaded = true;
    const fileLength = this.fileInputRef.nativeElement.files.length;
    const fileValidation = this.fileInputRef.nativeElement.files[0].type;

    if (fileLength == 0) { return; }

    // validacion del tipo de archivo
    if (fileValidation !== "application/pdf") {
      this.commonService.presentAlert("solo se admiten archivos pdf");
      this.clearFile();
      return;
    }

    this.formFile = this.fileInputRef.nativeElement.files[0];
    console.log(this.formFile);
    this.onSelectFile.next(this.formFile);

    // clear input file
    this.fileInputRef.nativeElement.value = "";
  }

  clearFile() {
    this.fileLoaded = false;
    this.formFile = null;
    this.onSelectFile.next(null);
  }

}
