// firebase deploy --only hosting:app-admin-settle

import { Component } from '@angular/core';

// angular
import {
  Platform,
  ToastController,
  AlertController
} from '@ionic/angular';
import { Router } from '@angular/router';

// lib
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

//rxjs
import { timer } from "rxjs/observable/timer";
import { environment } from '../environments/environment';

// services
import {
  AuthenticationService,
  CommonService,
  LanguageService,
  FcmService,
  AppflowService,
  CustomizationfileService
} from "./services/services";

import { Zoom } from '@ionic-native/zoom/ngx';





@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  SDK_KEY = environment.zoom.SDK_KEY_SDK;
  SDK_SECRET = environment.zoom.SDK_SECRET_SDK;
  language = 'en-US';
  public showSplash = true;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public toastController: ToastController,
    public alertController: AlertController,
    public _auth: AuthenticationService,
    public _language: LanguageService,
    public _cs: CommonService,
    private _fcm: FcmService,
    public _cf: CustomizationfileService,
    private zoomService: Zoom
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this._isAuthenticated()

      this.zoomService.initialize(this.SDK_KEY, this.SDK_SECRET)
        .then((success) => {
          console.log(success);
        })
        .catch((error) => {
          console.log(error);
          // this.presentToast(error);
        });
      this.setLanguage()
      timer(300).subscribe(() => {
        this.showSplash = false
      });
    });
  }

  _isAuthenticated() {
    const keyDB = window.localStorage.getItem("keyDB");
    const keyInfo = window.localStorage.getItem("keyInfo");
    if (this._auth.isAuthenticated()) {
      if (this._cf.getDayConf() && this._cf.getStageConf()) {
        if (!environment.production) return
        //  this.router.navigate(["members/tabs/dashboard"]);
      } else {
        if (!environment.production) return
        // this.router.navigate(["members/config"]);
      }
    } else {
      if (keyDB && keyInfo) {
        this.router.navigate(["login"]);
      } else {
        this.router.navigate(["welcome"]);
      }
    }
  }

  setLanguage() {
    this.zoomService.setLocale(this.language).then((success) => {
      console.log('setLanguage', success);
    }).catch((error) => {
      console.log(error);
      // this.presentToast(error);
    });
  }


  private async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 5000
    });
    toast.present();
  }
}
