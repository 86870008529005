import { Component } from "@angular/core";
import { ModalController, NavParams, LoadingController } from "@ionic/angular";

@Component({
  selector: "app-information",
  templateUrl: "./information.component.html",
  styleUrls: ["./information.component.scss"]
})
export class InformationComponent {
  public details: any = [];
  constructor(private modalCtrl: ModalController, private params: NavParams, public loadingController: LoadingController ) {
    this.details = this.params.get("details");
    console.log(this.details);
  }

  getHora(i, s) {
    let suma = i * 3 + s;
    return this.convertMinsToHrsMins(suma);
  }

  convertMinsToHrsMins(mins) {
    if (!mins) return;
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
