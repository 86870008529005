export { CustomizationfileService } from './customizationfile/customizationfile.service';
export { NotificationService } from './notification/notification.service';
export { RolService } from './rol/rol.service';
export { AppflowService } from "./appflow/appflow.service";
export { AuthenticationService } from "./auth/authentication.service";
export { CommonService } from "./common/common.service";
export { FcmService } from "./fcm/fcm.service";
export { LanguageService } from "./language/language.service";
export { NotificationPushService } from "./notificationpush/notification-push.service";
export { IsPlatformService } from "./platform/is-platform.service";
export { RatingsService } from "./ratings/ratings.service";
export { SocialService } from "./social/social.service";
export { ViewStatusService } from "./viewStatus/view-status.service";
export { PermissionService } from './permission/permission.service'
export { PersistenceDayAndStageService } from './persistenceDayAndStage/persistence-day-and-stage.service'
export { NewBackendService } from '../services/new_backend/new_backend'
export { DataService } from '../services/data/data.service'
export { UploadFileService } from './uploadFile/upload-file.service'



