import { Injectable } from '@angular/core';
// import { Pro } from '@ionic/pro';



@Injectable({
  providedIn: 'root'
})
export class AppflowService {

  public deployChannel = "";
  public isBeta = false;
  public downloadProgress = 0;

  constructor() { }

 /* async checkChannel() {
    try {
      const res = await Pro.deploy.getConfiguration();
      console.log("checkChannel 1", res)
      this.deployChannel = res.channel;
      console.log("this.deployChannel"), this.deployChannel
      this.isBeta = (this.deployChannel === 'Beta')
    } catch (err) {
      // We encountered an error.
      // Here's how we would log it to Ionic Pro Monitoring while also catching:
      console.log("checkChannel 2", err)
      Pro.monitoring.exception(err);
    }
  }

  async toggleBeta() {
    const config = {
      channel: (this.isBeta ? 'Beta' : 'Production')
    }

    try {
      await Pro.deploy.configure(config);
      await this.checkChannel();
      // await this.sync({ updateMethod: 'auto' }); // Alternatively, to customize how this works, use performManualUpdate()
      this.performManualUpdate()
    } catch (err) {
      // We encountered an error.
      // Here's how we would log it to Ionic Pro Monitoring while also catching:

      // Pro.monitoring.exception(err);
    }

  }

  async performManualUpdate() {

    try {
      const update = await Pro.deploy.checkForUpdate();
      console.log("Pro.deploy.checkForUpdate()", update.available)
      if (update.available) {
        this.downloadProgress = 0;
        console.log("this.downloadProgress", this.downloadProgress)

        await Pro.deploy.downloadUpdate((progress) => {
          this.downloadProgress = progress;
        })
        await Pro.deploy.extractUpdate();
        await Pro.deploy.reloadApp();
      }
    } catch (err) {
      // We encountered an error.
      // Here's how we would log it to Ionic Pro Monitoring while also catching:
      console.log("performManualUpdate", err)
      Pro.monitoring.exception(err);
    }

  }*/
}
