import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ngfor"
})
export class NgforPipe implements PipeTransform {

  /**
   * 
   * @param value 
   * @returns 
   */
  transform(value: any): any {
    if (!value) return;
    let _category = [];
    let count = 0;
    if (Array.isArray(value)) {
      value.forEach(elem => {
        _category.push(elem);
      });
    } else {
      Object.keys(value).forEach(i => {
        _category.push(Object.assign(value[i], { name: i }));
      });
    }
    return _category;
  }
}
