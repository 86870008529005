import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { AngularFireDatabase } from "@angular/fire/database";
import { CustomizationfileService, CommonService } from "../../../../services/services";

@Component({
  selector: "app-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"]
})
export class ScheduleComponent {
  // public code: any;
  public calendar: any = [];
  public h: any;
  public m: any;
  skeleton = false
  constructor(
    private params: NavParams,
    private modalCtrl: ModalController,
    public db: AngularFireDatabase,
    public _cf: CustomizationfileService,
    public _cs: CommonService
  ) {
    const code = this._cs.clearCode(this.params.get("code"))
    this.seeSchedules(code);
  }

  async seeSchedules(code) {

    this.calendar = [];
    const url = `categoryCalendars/${this._cf.getKeyDb()}`;
    for (let index = 1; index < 8; index++) {
      await this.db
        .object(`${url}/day${index}/${code}`)
        .valueChanges()
        .subscribe(data => {
          if (data) {
            this.calendar.push(Object.assign({ day: `day${index}` }, data));
          }
        });
    }
    this.skeleton = true
  }

  getStarted(mins) {
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  getFinalHour(mins) {
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
