import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { IonicModule } from '@ionic/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Component
import { SidebarMenuComponent } from "./components/sidebar-menu/sidebar-menu.component";
import { ExpandableComponent } from "./components/expandable/expandable.component";

// Pipes
import { BackstagePipe } from '../pipes/backstage/backstage.pipe';
import { NgforPipe } from "../pipes/Ngfor/ngfor.pipe";
import { KeysPipe } from "../pipes/keys/keys.pipe";
import { ValidProfilePipe } from '../pipes/validProfile/valid-profile.pipe';
import { SortValidPipe } from '../pipes/sortValid/sort-valid.pipe';
import { SortPhotoYCityPipe } from '../pipes/sortValid/sort-photo-ycity.pipe';
import { SortBackstagePipe } from '../pipes/sortBackstage/sort-backstage.pipe';
import { BackstageShowPipe } from '../pipes/backstage-show/backstage-show.pipe';
import { MildSeriousErrorPipe } from "../members/results/pipes/mild-serious-error.pipe";
import { OrderResultsPipe } from "../members/results/pipes/order-results.pipe";
import { CallBackstagePipe } from '../pipes/call-backstage/call-backstage.pipe';
import { FileSizeFormatPipe } from "../pipes/fileSize/file-size.pipe";
import { TotalQualificationPipe } from "../members/results/pipes/total-qualification.pipe";
import { FormatFileSizePipe } from "../pipes/formatFileSize/format-file-size.pipe";
import { SkeletonComponent } from "./components/skeleton/skeleton.component";
import { ScheduleComponent } from './components/qr-modal/schedule/schedule.component'
import { ParticipantsComponent } from "./components/qr-modal/participants/participants.component";
import { InformationComponent } from "./components/information/information.component";
import { ViewUserComponent } from "../members/official-list-of-departures/view-user/view-user.component";
import { CustomEmailNotificationFormComponent } from "./components/custom-email-notification-form/custom-email-notification-form.component";
import { CloudResultsPipe } from "../members/results/pipes/cloud-results.pipe";
import { CategoryCheckPipe } from "../members/results/pipes/category-check.pipe";
import { NumberOfCompetitorsPipe } from "../members/results/pipes/number-of-competitors.pipe";
import { AdminFormUpdateUserEmailComponent } from "./components/admin-form-update-user-email/admin-form-update-user-email.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { PopoverComponent } from "./components/popover/popover.component";
import { CustomInputFileComponent } from "./components/custom-input-file/custom-input-file.component";
import { CustomInputPhotoComponent } from "./components/custom-input-photo/custom-input-photo.component";
import { FullScreenPhotoComponent } from "./components/full-screen-photo/full-screen-photo.component";
import { QrcodeComponent } from "./components/qrcode/qrcode.component";
import { SignatureSilleterosComponent } from "./components/signature-silleteros/signature-silleteros.component";
import { CustomInputVideoComponent } from "./components/custom-input-video/custom-input-video.component";
import { CustomInputFilePhotoComponent } from "./components/custom-input-file-photo/custom-input-file-photo.component";
import { CaliResultPipe } from '../members/results/pipes/cali-result.pipe';
import { EditErrorResultComponent } from '../members/results/edit-result/edit-error-result/edit-error-result.component';
import { EditResultsComponent } from '../members/results/edit-result/edit-results/edit-results.component';
import { ScanQrCheckComponent } from '../members/check-in-automatic/components/scan-qr-check/scan-qr-check.component';

@NgModule({
    imports: [
        IonicModule,
        RouterModule,
        TranslateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularEditorModule,
        AngularEditorModule,
        ZXingScannerModule,
        TranslateModule
    ],
    declarations: [
        SidebarMenuComponent,
        ExpandableComponent,
        BackstagePipe,
        ValidProfilePipe,
        NgforPipe,
        KeysPipe,
        SortValidPipe,
        SortPhotoYCityPipe,
        SortBackstagePipe,
        BackstageShowPipe,
        MildSeriousErrorPipe,
        OrderResultsPipe,
        CallBackstagePipe,
        FileSizeFormatPipe,
        TotalQualificationPipe,
        FormatFileSizePipe,
        ScheduleComponent,
        ParticipantsComponent,
        SkeletonComponent,
        InformationComponent,
        ViewUserComponent,
        CustomEmailNotificationFormComponent,
        CloudResultsPipe,
        CategoryCheckPipe,
        NumberOfCompetitorsPipe,
        AdminFormUpdateUserEmailComponent,
        PopoverComponent,
        CustomInputFileComponent,
        CustomInputPhotoComponent,
        FullScreenPhotoComponent,
        QrcodeComponent,
        SignatureSilleterosComponent,
        CustomInputVideoComponent,
        CustomInputFilePhotoComponent,
        EditResultsComponent,
        EditErrorResultComponent,
        CaliResultPipe,
        ScanQrCheckComponent,

    ],
    exports: [
        SidebarMenuComponent,
        ExpandableComponent,
        ScheduleComponent,
        ParticipantsComponent,
        SkeletonComponent,
        InformationComponent,
        BackstagePipe,
        ValidProfilePipe,
        NgforPipe,
        KeysPipe,
        SortValidPipe,
        SortPhotoYCityPipe,
        SortBackstagePipe,
        BackstageShowPipe,
        MildSeriousErrorPipe,
        OrderResultsPipe,
        FileSizeFormatPipe,
        CallBackstagePipe,
        TotalQualificationPipe,
        FormatFileSizePipe,
        ViewUserComponent,
        CustomEmailNotificationFormComponent,
        CloudResultsPipe,
        CategoryCheckPipe,
        NumberOfCompetitorsPipe,
        AdminFormUpdateUserEmailComponent,
        PopoverComponent,
        CustomInputFileComponent,
        CustomInputPhotoComponent,
        FullScreenPhotoComponent,
        QrcodeComponent,
        SignatureSilleterosComponent,
        CustomInputVideoComponent,
        CustomInputFilePhotoComponent,
        CaliResultPipe,
        EditResultsComponent,
        EditErrorResultComponent,
        ScanQrCheckComponent,

        AngularEditorModule,
        TranslateModule

    ],
    entryComponents: [
        InformationComponent,
        ViewUserComponent,
        CustomEmailNotificationFormComponent,
        AdminFormUpdateUserEmailComponent,
        CustomInputFileComponent,
        CustomInputPhotoComponent,
        FullScreenPhotoComponent,
        QrcodeComponent,
        SignatureSilleterosComponent,
        CustomInputVideoComponent,
        CustomInputFilePhotoComponent,
        EditResultsComponent,
        EditErrorResultComponent,
        ScanQrCheckComponent

    ]
})
export class SharedModule { }
