import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TranslationService implements TranslateLoader {

  constructor(private http: HttpClient) { }

  getTranslation(lang: string): Observable<any> {
    // let url = `${environment.urlrootFunctions}/v2/languages/language?lang=en`
    // console.log("url", url)
    return this.http.get(`${environment.urlrootFunctions}/v2/languages/language?lang=${lang}`)
      .pipe(map((response: JSON) => {
        return response;
      }))
  }
}
