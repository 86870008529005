import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caliResult'
})
export class CaliResultPipe implements PipeTransform {

  /**
   * 
   * @param score 
   * @returns 
   */
  transform(score: any): any {
    return score / 10;
  }



}
