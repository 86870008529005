import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from "firebase/app";
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { CustomizationfileService } from '../customizationfile/customizationfile.service';


export interface Mensaje {
  nombre: string;
  mensaje: string;
  fecha?: number;
  uid?: string;
  avatar?: string
}

export interface Post {
  message: string;
  likes: number;
  username: string;
  id?: any;
  date: any;
  photo: any;
  img: any;
  key?: any,
  uid: any
}

export interface Chat {
  msn: string;
  username: string;
  date: string;
}


export interface Item { uid: string; }

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  private itemsCollection: AngularFirestoreCollection<Mensaje>;
  public chats: Mensaje[] = [];
  public usuario: any = {};

  private postCollection: AngularFirestoreCollection<Post>;
  posts$: Observable<Post[]>;
  private postDoc: AngularFirestoreDocument<Post>;



  private commentCollection: AngularFirestoreCollection<Post>;
  comment$: Observable<Post[]>;
  private commentDoc: AngularFirestoreDocument<Post>;
  commentItems: Observable<any[]>;


  private userDoc: AngularFirestoreCollection<Item>;
  user: Observable<Item[]>;
  _likes = true

  constructor(private afs: AngularFirestore,
    public afAuth: AngularFireAuth, public _cf: CustomizationfileService) {
    this.profile()
    this.post()

  }

  profile() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        let profile = this._cf.getprofile()
        let name = `${profile.name} ${profile.surnames}`
        this.usuario.nombre = name;
        this.usuario.avatar = this._cf.getavatar();
        this.usuario.uid = this._cf.getUid();
      }

    });
  }

  /**
   * Chat
   */
  cargarMensajes() {
    this.itemsCollection = this.afs.collection<Mensaje>('chats', ref => ref.orderBy('fecha', 'desc').limit(40));
    return this.itemsCollection.valueChanges()
      .pipe(map((mensajes: Mensaje[]) => {
        this.chats = [];

        for (let mensaje of mensajes) {
          this.chats.unshift(mensaje);
        }

        return this.chats;
        // this.chats = mensajes;
      }));
  }

  agregarMensaje(texto: string) {
    let mensaje: Mensaje = {
      nombre: this.usuario.nombre,
      mensaje: texto,
      fecha: new Date().getTime(),
      avatar: this.usuario.avatar,
      uid: this.usuario.uid
    };

    return this.itemsCollection.add(mensaje);
  }

  /**
   * Post
   */


  post() {
    this.postCollection = this.afs.collection<Post>('post', ref => ref.orderBy("date", 'desc').limit(20));
    this.posts$ = this.postCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Post;
        const key = a.payload.doc.id;
        return { key, ...data };
      }))
    );
  }

  getPosts() {
    return this.posts$;
  }

  addPost(item: Post) {
    this.postCollection.add(item);
  }

  getPostsCollection() {
    return this.postCollection;
  }

  /**
  * 
  * likes de publicaciones
  */


  public likesPost(key): void {
    this._likes = false
    const documentReference = this.postCollection.doc(key);

    // running the transaction
    firebase.firestore().runTransaction(t => {

      // returning the transaction function
      return t.get(documentReference.ref)
        .then(doc => {

          // read the current "value" field of the document
          const newValue = doc.data().likes + 1;

          // increase it by 1 atomically
          t.update(documentReference.ref, {
            likes: newValue
          });

        });
    }).then(res => this._likes = true, err => console.error(err));
  }

  /**
   * Comentarios de publicacion
   */

  getComment(key) {
    this.commentCollection = this.afs.collection<Post>('comment', ref => ref.where('key', '==', key).orderBy("date", 'desc').limit(20));
    this.comment$ = this.commentCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Post;
        const key = a.payload.doc.id;
        return { key, ...data };
      }))
    );
  }

  addComment(item) {
    this.commentCollection.add(item);

  }

  getCommentCollection() {
    return this.commentCollection;
  }

}
