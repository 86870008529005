import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Pipe({
  name: 'numberOfCompetitors'
})
export class NumberOfCompetitorsPipe implements PipeTransform {
  // /globalQualificationRecord/iMo9FDET3HndrQY5wVhX/day1/tsalon/participation

  constructor(public db: AngularFireDatabase) { }

  /**
   * 
   * @param KeyDb 
   * @param day 
   * @param code 
   * @returns 
   */
  async transform(KeyDb: string, day: string, code: string): Promise<any> {
    const number: any = await this.getNumberOfCompetitors(KeyDb, day, code);
    return number;
  }


  /**
   * 
   * @param KeyDb 
   * @param day 
   * @param code 
   * @returns 
   */
  getNumberOfCompetitors(KeyDb: string, day: string, code: string) {
    return new Promise((resolve, reject) => {
      this.db.object(`/globalQualificationRecord/${KeyDb}/${day}/${code}/participation`)
        .valueChanges()
        .subscribe((data: any) => {
          if (!data) return resolve(0)
          // console.log(data)
          resolve(Object.keys(data).length)
        })
    })
  }


}
