import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-custom-email-notification-form',
  templateUrl: './custom-email-notification-form.component.html',
  styleUrls: ['./custom-email-notification-form.component.scss'],
})
export class CustomEmailNotificationFormComponent implements OnInit {

  public form: FormGroup;
  public vm = {
    subject: [
      {type: 'required', message: 'Subject is required'},
      {type: 'minlength', message: 'Subject must be at least 3 characters long'},
      {type: 'pattern', message: 'Subject must contain only letters, numbers, spaces, and dashes'}
    ],
    body: [
      {type: 'required', message: 'Body is required'},
      {type: 'minlength', message: 'Body must be at least 3 characters long'},
    ]
  };
  public submitted = false;
  public editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    // uploadUrl: 'v1/image',
    // upload: (file: File) => {
    //   console.log('upload', file);
    //   return file;
    // },
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    public alertCtrl: AlertController,
  ) {
    this.form = this.fb.group({
      subject: [
        '',
        [
          Validators.required, 
          Validators.minLength(3),
          Validators.pattern(/^[a-zA-Z0-9 \-]+$/)
        ]
      ],
      body: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
        ]
      ]
    });
  }

  ngOnInit() {}

  get f(){ return this.form.controls; }

  async onSubmit(){
    try {
      this.submitted = true;

      if(this.form.invalid){
        this.form.markAllAsTouched();
        return;
      }

      const formData = this.form.value;
      const data = {
        subject: `${formData.subject}`.trim(),
        body: `${formData.body}`.trim(),
      }

      return this.dismiss(data, 'next');

    } catch (err) {
      console.log('Error on CustomEmailNotificationFormComponent.onSubmit', err);
      return;
    }
  }

  dismiss(data = {}, role = 'cancel') {
    this.modalCtrl.dismiss({...data}, role);
  }

}
