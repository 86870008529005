import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
export class QrcodeComponent implements OnInit, AfterViewInit {


  isCameraAvailable = false;
  isCameraAuthorized = false;
  isCameraOpen = false;
  isValid = false;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  scanning = false;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() { }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scanning = true;
    });
  }


  onCamerasFound(devices: MediaDeviceInfo[]): void {
    console.log('Devices: ', devices);
    this.availableDevices = devices;

    if (this.availableDevices && this.availableDevices.length > 0) {
      this.currentDevice = this.availableDevices[0];
    }
  }

  onScanSuccess(result: string) {
    console.log('Scanned successfully! Result: ', result);
    this.closeModal(result);
    // Aquí puedes realizar acciones con el resultado obtenido del escaneo.
  }

  onCameraSelect(event: any) {
    console.log('Camera selected: ', event);
  }

  onCapabilitiesSelect(event: any) {
    console.log('Capabilities selected: ', event);
  }

  onHasPermission(hasPermission: boolean) {
    console.log('Camera permission: ', hasPermission);
    this.isCameraAuthorized = hasPermission;
  }

  onDeviceChange(event: any) {
    console.log('Device change: ', event);
    this.isCameraAvailable = event.deviceCount > 0;
  }

  toggleCamera() {
    this.isCameraOpen = !this.isCameraOpen;
  }


  /**
* 
* @param photoBlob 
*/
  closeModal(text) {
    this.modalCtrl.dismiss({
      qr: text
    });
  }
}
