import { Component, ElementRef, OnInit, HostListener, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-custom-input-photo',
  templateUrl: './custom-input-photo.component.html',
  styleUrls: ['./custom-input-photo.component.scss'],
})
export class CustomInputPhotoComponent implements OnInit {

  // private videoElement!: HTMLVideoElement;
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;
  @ViewChild('canvasElement', { static: false }) canvasElement: ElementRef;
  private videoStream: MediaStream;
  public isPreviewing = false;


  constructor(private modalCtrl: ModalController) { }

  ngOnInit(): void {
    setTimeout(async () => {
      // await this.getDevices();
      // await this.setDefaultVideoSource();
      this.startPreview('environment')
    }, 300);
  }


  toggleCamera() {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
      if (this.videoStream.getVideoTracks()[0].getSettings().facingMode === 'environment') {
        this.startPreview('user');
      } else {
        this.startPreview('environment');
      }
    } else {
      console.error('Camera stream is not available.');
    }
  }


  /**
   * Inicia la cámara y muestra el video en el elemento HTMLVideoElement.
   */
  async startPreview(facingMode: 'user' | 'environment') {
    try {
      this.videoStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode,
          width: { ideal: 300 }, // Change the width to the desired resolution
          height: { ideal: 300 }, // Change the height to the desired resolution
        },
      });
      this.videoElement.nativeElement.srcObject = this.videoStream;
      this.videoElement.nativeElement.play();
      this.isPreviewing = true;
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  }



  /**
   * Detiene la cámara y limpia el elemento HTMLVideoElement.
   */
  stopPreview() {
    this.videoElement.nativeElement.pause();
    this.videoStream.getTracks().forEach(track => track.stop());
    this.isPreviewing = false;
  }


  /**
   * Toma una foto y la muestra en el elemento HTMLCanvasElement.
   */
  async takePhoto() {
    // const context = this.canvasElement.nativeElement.getContext('2d');
    // context.drawImage(this.videoElement.nativeElement, 0, 0, this.canvasElement.nativeElement.width, this.canvasElement.nativeElement.height);
    // const photoBlob = await this.getCanvasBlob(this.canvasElement.nativeElement);
    // console.log(photoBlob);

    const video = this.videoElement.nativeElement;
    this.canvasElement.nativeElement.width = video.videoWidth;
    this.canvasElement.nativeElement.height = video.videoHeight;

    const context = this.canvasElement.nativeElement.getContext('2d');
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const photoBlob = await this.getCanvasBlob(this.canvasElement.nativeElement);


    const blobToBase64 = await this.blobToBase64(photoBlob)

    // Aquí puedes hacer lo que desees con la photoBlob, por ejemplo, mostrarla en la interfaz o enviarla al servidor.
    this.closeModal(blobToBase64, photoBlob);
  }

  /**
   * 
   * @param photoBlob 
   */
  closeModal(blobToBase64, recordedBlob) {
    this.stopPreview();
    this.modalCtrl.dismiss({
      'url': blobToBase64,
      'blob': recordedBlob,
      'type': 'photo',
      "ext": ".png"
    });
  }


  /**
   * 
   * @param canvas 
   * @returns 
   */
  private getCanvasBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve(blob as Blob);
      });
    });
  }

  /**
   * 
   * @param blob 
   * @returns 
   */
  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('Failed to convert Blob to Base64');
        }
      };
      reader.readAsDataURL(blob);
    });
  }

}

