import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "totalQualification"
})
export class TotalQualificationPipe implements PipeTransform {

  /**
   * 
   * @param value 
   * @returns 
   */
  transform(value: any): any {
    if (value) {
      let result = [];
      for (let i = 0; i < value.length; i++) {
        let duplicates = this.count(value[i].allcategory);
        let r = Object.assign(value[i], { duplicates: duplicates });
        result.push(r);
      }
      const resultCalculateAverage = this.calculateAvargaureByCategory(result);
      const rca = this.calculateAvargaure(resultCalculateAverage);
      return rca;
    }

  }

  // Modificado y probado
  /**
   * 
   * @param data 
   * @returns 
   */
  count(data) {
    if (data == undefined) return [];
    const result = data.reduce((prev, curr) => {
      const index = prev.findIndex(el => el.name === curr.name);
      if (index !== -1) {
        prev[index].nro += 1;
        prev[index].scoreF += curr.score;
      } else {
        prev.push({
          block: curr.block,
          name: curr.name,
          nro: 1,
          scoreF: curr.score,
          value: parseFloat(curr.value)
        });
      }

      return prev;
    }, []);

    return result;
  }

  /**
   * @dev listo
   * @param data 
   * @returns 
   */
  calculateAvargaureByCategory(data) {
    if (data == undefined) return [];
    let resultFinal = [];
    data.forEach(x => {
      let tp = 0;
      let result = [];
      x.duplicates.forEach(c => {
        tp = ((parseFloat(c.scoreF) / 10 / c.nro) * parseFloat(c.value)) / 10;
        result.push(
          Object.assign(
            { name: c.name },
            { total: parseFloat(tp.toFixed(2)) },
            { unmodified: c.scoreF }
          )
        );
      });
      const rf = Object.assign({ totalbyCategory: result }, x);
      resultFinal.push(rf);
    });
    return resultFinal;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  calculateAvargaure(data) {
    if (data == undefined) return [];
    let result = [];
    data.forEach(x => {
      let finalScore = 0;
      x.totalbyCategory.forEach(c => {
        finalScore = finalScore + c.total;
      });
      result.push(
        Object.assign(
          {
            finalScore: finalScore - (x.milderrors + x.seriousErrors),
            milderrors: x.milderrors,
            seriousErrors: x.seriousErrors
          },
          x
        )
      );
    });

    return result;
  }
}
