import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validProfile'
})
export class ValidProfilePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // console.log("value", value)
    // return value.filter(obj => obj.confirm === true);

  }

}
