import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import RecordRTC from 'recordrtc';

@Component({
  selector: 'app-custom-input-video',
  templateUrl: './custom-input-video.component.html',
  styleUrls: ['./custom-input-video.component.scss'],
})
export class CustomInputVideoComponent implements AfterViewInit {
  private recordRTC!: RecordRTC;
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;
  @ViewChild('canvasElement', { static: false }) canvasElement: ElementRef;
  private videoStream: MediaStream;
  public isPreviewing = false;
  public isRecording = false;

  public recordingInterval: any;
  public videoDuration: number = 0;



  constructor(private modalCtrl: ModalController) { }

  ngAfterViewInit() {
    setTimeout(async () => {
      this.startPreview('environment')
    }, 300);
  }




  /**
 * Inicia la cámara y muestra el video en el elemento HTMLVideoElement.
 */
  async startPreview(facingMode: 'user' | 'environment') {
    try {
      this.videoStream = await navigator.mediaDevices.getUserMedia({
        video:
        {
          facingMode,
          width: { ideal: 300 }, // Change the width to the desired resolution
          height: { ideal: 300 }, // Change the height to the desired resolution
        }, audio: false
      });
      this.videoElement.nativeElement.srcObject = this.videoStream;
      this.videoElement.nativeElement.play();
      this.isPreviewing = true;
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  }

  toggleCamera() {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
      if (this.videoStream.getVideoTracks()[0].getSettings().facingMode === 'environment') {
        this.startPreview('user');
      } else {
        this.startPreview('environment');
      }
    } else {
      console.error('Camera stream is not available.');
    }
  }


  /**
   * Detiene la cámara y deja de mostrar el video en el elemento HTMLVideoElement.
   */
  stopPreview() {
    this.videoElement.nativeElement.pause();
    this.videoStream.getTracks().forEach(track => track.stop());
    this.isPreviewing = false;
  }

  /**
   * Convierte un Blob a Base64.
   */
  async startRecording() {
    try {
      this.recordRTC = RecordRTC(this.videoStream, {
        type: 'video',
        mimeType: 'video/webm',
      });


      // Agregar evento ondataavailable para calcular la duración del video grabado
      this.recordingInterval = setInterval(() => {
        this.videoDuration++;
      }, 1000);

      this.recordRTC.startRecording();

      this.isRecording = true;
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  }


  /**
   * Convierte un Blob a Base64.
   */
  async stopRecording() {
    this.recordRTC.stopRecording(async () => {

      // Restablecer la duración del video grabado a cero cuando se detiene la grabación
      this.videoDuration = 0;



      const recordedBlob = this.recordRTC.getBlob();
      // Aquí tienes el Blob del video grabado, puedes mostrarlo en la interfaz o enviarlo al servidor.
      const blobToBase64 = await this.blobToBase64(recordedBlob)
      this.closeModal(blobToBase64, recordedBlob)
    });

    this.isRecording = false;
    clearInterval(this.recordingInterval);

  }

  formatDuration(duration: number): string {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }


  /**
 * 
 * @param photoBlob 
 */
  closeModal(blobToBase64, recordedBlob) {
    this.stopPreview();
    this.modalCtrl.dismiss({
      'url': blobToBase64,
      'blob': recordedBlob,
      'type': 'video',
      "ext": ".webm"
    });
  }

  /**
   * 
   * @param blob 
   * @returns 
   */
  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('Failed to convert Blob to Base64');
        }
      };
      reader.readAsDataURL(blob);
    });
  }


}
