import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService, CommonService, CustomizationfileService } from '../services/services';


@Injectable({
  providedIn: 'root'
})
export class CheckEventKey implements CanActivate {

  constructor(
    public commonService: CommonService,
    private router: Router,
    private authSrv: AuthenticationService,
    private _cf: CustomizationfileService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise(async (resolve) => {

      const isKey = this._cf.getKeyDb();
      console.log("CheckEventKey", isKey)

      if (isKey) {
        return resolve(true);
      } else {
        await this.authSrv.logoutGuard();
        await this.commonService.presentAlert("Debes seleccionar un evento");
        this.router.navigate(["/welcome"]);
        return resolve(false);
      }

    });
  }
}
