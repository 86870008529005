import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { map } from "rxjs/operators";
import { CommonService } from "../common/common.service";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: "root"
})
export class NotificationPushService {
  public uid: any;
  public categoryPush = [];
  public url: string;
  constructor(
    public db: AngularFireDatabase,
    private http: HttpClient,
    public _cs: CommonService
  ) {
    this.url = this._cs.urlrootFunctions;
  }

  getCategory(uid) {
    return new Promise(async resolve => {
      if (uid) {
        const url = `competition/${this._cs.key}/participate_category`;
        const itemsRef = this.db.list(url, ref =>
          ref
            .orderByChild("uid")
            .startAt(uid)
            .endAt(uid)
        );

        // Obtenemos todos los cursos
        itemsRef
          .snapshotChanges()
          .pipe(
            map(changes =>
              changes.map(c => ({ key: c.payload.key, ...c.payload.val() as any }))
            )
          )
          .subscribe(category => {
            if (category) {
              this.registeTopic(category).then(async (data: any) => {
                let result = await this.removeDuplicates(data);
                resolve(result);
              });
            }
          });
      } else {
        resolve();
      }
    });
  }

  registeTopic(category) {
    return new Promise(async resolve => {
      if (category) {
        let categoryPush = [];
        await category.forEach(x => {
          x.categories.forEach(category => {
            let result = {
              name: category.category_detail.sub_categoria,
              code: category.category_detail.id_sub_categoria,
              isChecked: true
            };
            categoryPush.push(result);
          });
        });
        console.log(4, categoryPush);
        resolve(categoryPush);
      }
    });
  }

  removeDuplicates(data) {
    if (data) {
      let result = data.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(t => t.name === thing.name && t.code === thing.code)
      );
      return result;
    }
  }

  subscribeToTopic(data) {
    return new Promise(async (resolve, reject) => {
      const _url = `${this.url}/subscribeToTopic`;
      this.http.post(_url, JSON.stringify(data)).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  unsubscribeFromTopic(data) {
    return new Promise(async (resolve, reject) => {
      const _url = `${this.url}/unsubscribeFromTopic`;
      this.http.post(_url, JSON.stringify(data)).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getToken() {
    return localStorage.getItem("tokenPush");
  }
}
