import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { NewBackendService, CustomizationfileService, CommonService } from "../../../services/services";

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss'],
})
export class ViewUserComponent implements OnInit {
  details: any;
  view_user_object: any
  constructor(private modalCtrl: ModalController,
    private params: NavParams,
    public _cf: CustomizationfileService,
    public _new: NewBackendService,
    public _cs: CommonService) {
    this.details = this.params.get("details");
    console.log('this.details', this.details)
  }

  ngOnInit() {
    this._new.getOfficiaListOfDeparturesUser({
      day: this._cf.getDayConf(),
      stage: this._cf.getStageConf(),
      user_key: this.details.key,
      keydb: this._cf.getKeyDb(),
    }).then((data: any) => {
      console.log('data', data)
      if (data.status) {
        this.view_user_object = data.data
      } else {
        alert('erro')
      }
    }, err => {
      alert(JSON.stringify(err))
    })
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  downloadUrl(url, filename) {
    this._cs.downloadUrl(url, `${this.view_user_object.info.code}-${this.view_user_object.participants.members}-${filename}`)
  }

}
